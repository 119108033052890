/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Layout from '../components/layout';
import * as queryString from 'query-string';
import { loginWithToken, checkRefreshTokenAndRedirect } from '../state/session';
import { showNotification } from '../state/notifications';
import { isBrowser } from '../utils';
import Spinner from '../components/Spinner';
import * as analytics from '../utils/analytics';

const tryJsonParse = str => {
  if (!str) {
    return;
  }
  try {
    return JSON.parse(str);
  } catch (err) {
    return;
  }
};

const TokenPage = ({ pageContext }) => {
  analytics.usePageCategory('omat_sivut');
  const dispatch = useDispatch();
  const hash = isBrowser ? window.location.hash : '';
  const queryParams = queryString.parse(hash);
  const { afterLogin } = tryJsonParse(queryParams.state) || {};
  const accessToken = queryParams.access_token;
  const search = isBrowser ? window.location.search : '';
  const { redirect }  = queryString.parse(search);
  useEffect(() => {
    if (accessToken) {
      dispatch(loginWithToken(accessToken, localStorage.getItem('srm') || false, true, afterLogin));
      localStorage.removeItem('srm');
    } else if (redirect) {
      dispatch(checkRefreshTokenAndRedirect(redirect));
    } else {
      dispatch(showNotification('login.message.loginFailed'));
    }
  }, [accessToken, redirect, dispatch]);
  return (
    <Layout paths={pageContext.paths} locale={pageContext.locale || 'en'}>
      <Box sx={{ position: 'relative', minHeight: 400 }}>
        <Spinner size="medium" />
      </Box>
    </Layout>
  );
};

export default TokenPage;
